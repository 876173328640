.app {
    display: grid;
    place-items: center;
    background-color: #dadbd3;
    height: 100vh;
}

.app-body {
    display: flex;
    background-color: #ededed;
    height: 90vh;
    width: 90vw;
    box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}