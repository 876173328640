.login {
    background-color: #f8f8f8;
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
}

.login-container {
    padding: 100px;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12) 0 1px;
}

.login-container > img {
    object-fit: contain;
    height: 300px;
    margin-bottom: 40px;
}

.login-container > button {
    margin-top: 50px;
    text-transform: inherit !important;
    background-color: #9F9A6E;
    color: white;
}

.login-container > button:hover {
    background-color: #9F9A6E;
    color: white;
}