.chat {
    flex: 0.67;
    display: flex;
    flex-direction: column;
}

.chat-header {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid lightgrey;
}

.chat-header-info {
    flex: 1;
    padding-left: 20px;
}

.chat-header-info > h1 {
   margin-bottom: 3px;
   font-weight: 500;
}

.chat-header-info > p {
    color: gray;
}

.chat-info-header-right {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
}

.chat-body {
    flex: 1;
    background-image: url('https://www.filepicker.io/api/file/u5frNNlBQDQbBX0nh9Mg');
    background-repeat: repeat;
    background-position: center;
    padding: 30px;
    overflow : scroll;
}

.chat-body::-webkit-scrollbar {
    width: 0;
}

.chat-message {
    position: relative;
    background-color: #ffffff;
    font-size: 18px;
    padding: 10px;
    border-radius: 20px;
    width: fit-content;
    margin-bottom: 25px;
}

.chat-name {
    position: absolute;
    top: -16px;
    font-weight: 650;
    font-size: small;
}

.chat-timestamp {
    margin-left: 10px;
    font-size: small;
}

.chat-reciever {
    margin-left: auto;
    background-color: #dcf8c6;
}

.chat-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-top: 2px solid lightgray;
}

.chat-footer > form {
    flex: 1;
    display: flex;
}

.chat-footer > form > input {
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
}

.chat-footer > form > button {
    display: none;
}

.chat-footer > .MuiSvgIcon-root {
    padding: 10px;
    color: gray;
}

.delete-chat > .MuiSvgIcon-root:hover{
    color: red;
}

.dropdown-items {
   left: -40px;
}