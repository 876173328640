.sidebarChat {
    display: flex;
    padding: 20px;
    cursor: pointer;
    border-bottom: 2px solid #f6f6f6;
}

.sidebarChat:hover {
    background-color: #ebebeb;
}

.sidebarChat-info {
    margin-left: 15px;
}

.sidebarChat-info > h2 {
    font-size: 20px;
    margin-bottom: 5px;
}

a {
    text-decoration: none;
    color: black;
}