.sidebar {
    flex: 0.33;
    flex-direction: column;
    display: flex;
}

.hide {
    display: hidden;
}

.hide:hover {
    display: block;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgrey;
}

.sidebar-header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 5vw;
}

.sidebar-header-right > .MuiSvgIcon-root {
    margin-right: 2vw;
    font-size: 24px !important;
}


.sidebar-search {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    height: 39px;
    padding: 10px;
}

.sidebar-search-container {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
    border-radius: 20px;

}

.sidebar-search-container > form > input {
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
}

.sidebar-search-container > form {
    flex: 1;
    display: flex;
}

.sidebar-search-container > form > button {
    display: none;
}

.sidebar-search-container > .MuiSvgIcon-root {
    color: gray;
    padding: 10px;
}

.sidebar-chats {
    flex: 1;
    overflow: scroll;
    background-color: white;
}

.sidebar-chats::-webkit-scrollbar {
    width: 0;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-items {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-items:hover {
    cursor: pointer;
}

.dropdown-items > h3 {
    color: black;
    padding: 12px 16px;
    display: block;
}

.dropdown-items > h3:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-items {
    display: block;
}